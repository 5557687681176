import React from "react";
import "../Pricing.css";
import { Helmet } from "react-helmet";
import Carousel from "../../Home/components/CarouselV2";

function NatePricing(props) {
  return (
    <div className="container">
      <Helmet>
        <title>Nepeto - Pricing</title>
        <meta
          name="description"
          content="Explore our pricing options and choose the plan that best suits your needs. Discover transparent and competitive pricing for our products and services. Find the perfect pricing plan for your requirements."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, pricing, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <div class="columns center" style={{ marginTop: "5vh", textAlign: "center", display: "flex", flexDirection: "column" }}>
        <div style={{ textAlign: "center" }}>
          <img
            src="/assets/nate.png"
            alt="sale"
            style={{
              width: props.isMobile ? "10vh" : "calc(3.5vw + 5vh)",
              borderRadius: "500px",
            }}
          /><br />
          <strong style={{ color: "rgb(140, 62, 124)", fontSize: "calc(1vw + 1vh)" }}>Nate @ Hustle Buddies</strong>
        </div>
        <div style={{ textAlign: "center" }}>
        <strong style={{ color: "rgb(140, 62, 124)", textAlign: "center", marginLeft: "4vw", marginRight: "3vw", fontSize: "calc(3.5vw + 1vh)" }}>50% OFF!</strong><br/>
        {!props.isMobile && <strong style={{ color: "rgb(140, 62, 124)", textAlign: "center", marginLeft: "4vw", marginRight: "3vw", fontSize: "calc(1.5vw + 1vh)" }}>Exclusive Discount</strong>}
        </div>
      </div>
      <br/>
      <h1 style={{ fontSize: "150%", textAlign: "center", color: "rgb(140, 62, 124)" }}>To apply this amazing discount and start your <strong style={{color: "rgb(140, 62, 124)"}}>FREE TRIAL</strong>, please use the coupon code: "<strong style={{color: "rgb(140, 62, 124)"}}>HUSTLEBUDDIES</strong>" !</h1>
      <a href="http://hustlebuddiesofficial.com/p/free-nepeto-1000-workshop" target="_blank" rel="noreferrer" style={{fontSize: "150%", textAlign: "center", margin: 'auto', display: "block"}}>Click here for Hustle Buddies Nepeto Workshop</a>
      <div
        className="mx-5 aharoni"
        style={{
          marginTop: props.isMobile ? "10%" : "3%",
          marginBottom: props.isMobile ? "30%" : "3.5%",
          textAlign: !props.isMobile && "center",
        }}
      >
        <p
          className="is-size-2-mobile is-size-1-tablet"
          style={{ color: "#8c3e7c" }}
        >
          <strong style={{ color: "#8c3e7c" }}>Automated</strong> Product
          Sourcing for Amazon
        </p>
        <p
          className="is-size-6-mobile is-size-5-tablet"
          style={{ color: "#8c3e7c" }}
        >
          Our algorithm scans hundreds of{" "}
          <span
            style={{
              borderBottom: "1px solid #e6cede",
              color: "#8c3e7c",
            }}
          >
            retailers
          </span>
          , unique{" "}
          <span
            style={{
              borderBottom: "1px solid #e6cede",
              color: "#8c3e7c",
            }}
          >
            distributors
          </span>
          , and{" "}
          <span
            style={{
              borderBottom: "1px solid #e6cede",
              color: "#8c3e7c",
            }}
          >
            wholesale
          </span>{" "}
          suppliers,
          <br />
          providing you with a selection of thousands of{" "}
          <span style={{ backgroundColor: "#e6cede", color: "#8c3e7c" }}>
            profitable products
          </span>{" "}
          ready for resell on Amazon!{" "}
        </p>
        <br />
        <div className="container custom-carousel" style={{ marginTop: "2vh" }}>
          <Carousel isMobile={props.isMobile} />
        </div>
        <br />
      </div>

      <p style={{ textAlign: "center", color: "#8c3e7c", width: props.isMobile ? "90%" : "60%", margin: "auto", marginBottom: props.isMobile && "2vh" }}>Introduction Video by Nate @ Hustle Buddies</p>
      <iframe
        src="https://www.youtube-nocookie.com/embed/yCz5p407lSM?si=h-rIjW4IFSlmM0AV"
        title="YouTube video player"
        style={{
          margin: "auto",
          marginLeft: props.isMobile ? "5%" : "20%",
          marginBottom: props.isMobile ? "15vh" : "7vh",
          border: "2px solid #8c3e7c",
          padding: "1%",
          width: props.isMobile ? "90%" : "60%",
          height: props.isMobile ? "50vw" : "25vw",
        }}
        frameborder="0"
        allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
}
export default NatePricing;
