import React from 'react';

export const Loader = ({ size = 'small', }) => {

  const getSizeStyles = () => {
    switch(size) {
      case 'small': return { width: '1rem', height: '1rem', borderWidth: '2px' };
      case 'large': return { width: '3rem', height: '3rem', borderWidth: '4px' };
      default: return { width: '2rem', height: '2rem', borderWidth: '3px' };
    }
  };

  const loaderStyle = {
    border: '2px solid rgba(0,0,0,0.1)',
    borderTop: `${getSizeStyles().borderWidth} solid rgba(140,62,124,0.7)`,
    borderRadius: '50%',
    width: getSizeStyles().width,
    height: getSizeStyles().height,
    animation: 'spin 1s linear infinite',
    display: 'inline-block'
  };

  return (
    <>
      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
      <div style={loaderStyle} />
    </>
  );
};

export default Loader;