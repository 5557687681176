import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";

function ModalForVariationsTable(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [loadedVariations, setLoadedVariations] = useState(-999);
  const [asinExists, setAsinExists] = useState({});
  const [trigerRender, setTriggerRender] = useState(0);
  const [varData, setVarData] = useState({});
  const [loadingVarData, setLoadingVarData] = useState(true);

  useEffect(() => {
    const getVarData = async (asins) => {
      setLoadingVarData(true);
      const url = `https://server.nepeto.com/mobile_app_load_variation_data/${asins}/nepeto.com/`;

      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        });
        const data = await res.json();

        setVarData((prev) => {
          const mergedObject = { ...prev, ...data };
          return mergedObject
        })
      } catch (error) {
        console.error('Error:', error);
      }
      setLoadingVarData(false);
    }

    const updateExists = async () => {
      const accessToken = await getAccessTokenSilently();
      await fetch("https://server.nepeto.com/asin_exists_array/", {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(JSON.parse(props.variations === -999 ? loadedVariations : props.variations).map((vari) => "https://www.amazon.com/gp/product/" + vari['asin']))
      })
        .then((response) => response.json())
        .then((data) => {
          let existsAsins = []
          for (const [asin, res] of Object.entries(data)) {
            if (res !== "no") {
              setAsinExists((oldExists) => ({
                ...oldExists,
                [asin]: "https://www.nepeto.com/" + res + "?asin=" + asin,
              }));
              existsAsins.push(asin)
            } else {
              setAsinExists((oldExists) => ({
                ...oldExists,
                [asin]: res,
              }));
            }
          }
          setTriggerRender((tr) => (tr + 1));
          if (existsAsins.length > 0) {
            getVarData(existsAsins.join(","));
          }
        });
    }

    const getVariations = async () => {
      const accessToken = await getAccessTokenSilently();
      fetch("https://server.nepeto.com/get_variations/" + props.currAsin + "/", {
        mode: "cors",
        headers: { Authorization: `Bearer ${accessToken}` },
      }).then((response) => response.json())
        .then((data) => {
          setLoadedVariations(JSON.stringify(data))
        });;
    };

    if (props.variations === -999 && loadedVariations === -999) {  // Custom flag to fetch variations
      getVariations();
    } else if (!(loadedVariations === null || loadedVariations.length === 0 || JSON.parse(loadedVariations) === null)) {
      updateExists();
    }
  }, [props, getAccessTokenSilently, loadedVariations]);

  return (
    <div
      id="modal-js-example"
      className={
        "modal" + (props.modalForVariations !== "" ? " is-active" : "")
      }
      style={{ fontSize: "0.8vw" }}
    >
      <div className="modal-background" onClick={() => {
        props.setModalForVariations("");
      }}></div>

      <div
        className="modal-content"
        style={{ width: props.isMobile ? "90vw" : "70vw" }}
      >
        <div className="box" style={{ textAlign: "center" }}>
          <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
            Variations Table
          </p>
          <br />
          {(props.variations === -999 && loadedVariations === -999) ? <i
            className="fa fa-spinner fa-spin"
            aria-hidden="true"
            style={{ marginTop: "10%", marginBottom: "50%", fontSize: "1.5vw" }}
          ></i> : (loadedVariations === null || loadedVariations.length === 0 || JSON.parse(loadedVariations) === null) ? <span style={{ lineHeight: "50vh" }}>No Variations<br /></span> : <table
            class="table is-bordered products-table"
            style={{
              width: "100%",
              textAlign: "left",
              height: "100%",
              fontSize: "100%",
            }}
          >
            <thead>
              <tr>
                <th>ASIN</th>
                <th>Attributes</th>
                <th>Variation Data</th>
                <th>Check if it's in Nepeto</th>
              </tr>
            </thead>
            <tbody>
              {JSON.parse(props.variations === -999 ? loadedVariations : props.variations).sort((a, b) => ((asinExists.hasOwnProperty(a.asin) && (asinExists[a.asin] === "no")) ? 1 : -1) - ((asinExists.hasOwnProperty(b.asin) && (asinExists[b.asin] === "no")) ? 1 : -1)).map((vari, index) => {
                return (
                  <tr key={index} tr={trigerRender} style={{ backgroundColor: props.currAsin === vari["asin"] && "#e7dee4" }}>
                    <td style={{width: "25%"}}>
                    {varData[vari["asin"]] && <><img src={varData[vari["asin"]].image} alt={varData[vari["asin"]]} style={{width: "30%"}} onClick={() => window.open("https://www.amazon.com/dp/" + vari["asin"] + "?th=1&psc=1")}/><br/></>}
                      <a
                        href={"https://www.amazon.com/dp/" + vari["asin"] + "?th=1&psc=1"}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "underline", verticalAlign: "middle" }}
                      >
                        {vari["asin"]} {props.currAsin === vari["asin"] && " - Current"}
                      </a>
                    </td>
                    <td
                      style={{
                        verticalAlign: "middle",
                        whiteSpace: "pre-line",
                        width: "20%"
                      }}
                    >
                      {vari["attributes"].map((attribute) => {
                        return `${attribute["dimension"]}: ${attribute["value"]}\n`;
                      })}
                    </td>
                    <td
                      style={{
                        verticalAlign: "middle",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {varData[vari["asin"]] ? 
                      <span style={{color: "#8B3C7E"}}>{varData[vari["asin"]].offers} offers from <strong style={{color: "#8B3C7E"}}>${varData[vari["asin"]].price}+</strong><br/>
                      Variation Ratings: <strong style={{color: "#8B3C7E"}}>{varData[vari["asin"]].var_rating} (+{varData[vari["asin"]].rating_last_month} last month)</strong><br/>
                      Variation Reviews: <strong style={{color: "#8B3C7E"}}>{varData[vari["asin"]].var_reviews} (+{varData[vari["asin"]].reviews_last_month} last month)</strong><br/>
                      {varData[vari["asin"]].variation_sales !== -1 && <strong style={{color: "black", backgroundColor: "lightgreen", padding: "1px 7px", borderRadius: "30px", marginTop: "4px"}}>Variation Sales: {varData[vari["asin"]].variation_sales}/month</strong>}
                      </span> : 
                      (loadingVarData ? "Loading..." : "")}
                    </td>
                    <td style={{ width: "22%", verticalAlign: "middle", backgroundColor: asinExists.hasOwnProperty(vari["asin"]) && asinExists[vari["asin"]] !== "no" && props.currAsin !== vari["asin"] && "lightgreen" }}>
                      {asinExists.hasOwnProperty(vari["asin"]) ? (asinExists[vari["asin"]] === "no" ? "Does not exist in Nepeto." :
                        <a alt="Nepeto" href={asinExists[vari["asin"]]} target="_blank"
                          rel="noreferrer" >{props.currAsin === vari["asin"] ? "Current Variation" : "Found! Click here to open the deal in a new tab."}</a>) :
                        <button
                          style={{ borderRadius: "30px", fontSize: "100%", padding: "2px 12px", backgroundColor: "#8B3C7E", color: "white", borderColor: "black", border: "1px solid gray" }}
                        >
                          Checking...
                        </button>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>}
          <br />
          <div className="columns is-mobile is-centered">
            <button
              className="button is-rounded is-danger"
              style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
              onClick={() => {
                props.setModalForVariations("");
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalForVariationsTable;
