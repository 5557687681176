import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const Admin = (props) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [page, setPage] = useState(0);
  const [approved, setApproved] = useState(0);
  const [notApproved, setNotApproved] = useState(0);
  const [products, setProducts] = useState([]);
  const [password, setPassword] = useState("");
  const targetHash = '8a7400afda139446391d099a4f2c5ee373e44c9bff276cf57229df1cdb9b8b08';

  async function sha256(message) {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
  }

  // Update subscription status
  useEffect(() => {
    const updateProducts = async () => {
      const accessToken = await getAccessTokenSilently();
      fetch("https://server.nepeto.com/get_not_approved_products/" + (page + 1) + "/", {
        mode: "cors",
        headers: { Authorization: `Bearer ${accessToken}` },
      }).then((response) => response.json())
        .then((data) => {
          setProducts(data);
        });
    };

    if (!isLoading && isAuthenticated && password === targetHash) {
      updateProducts();
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated, password, page]);

  if (password !== targetHash) {
    return <div style={{ height: "80vh" }}>
      <input onChange={async (e) => setPassword(await sha256(e.target.value))} placeholder="Pass" />
    </div>
  } else {
    const productSetApprove = async (product_url, approved, source_name) => {
      const accessToken = await getAccessTokenSilently();
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json; charset=utf8",
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          product_url: product_url.replace(
            source_name,
            "X".repeat(source_name.length)
          ),
          approved: approved,
        }),
      };

      fetch(
        "https://server.nepeto.com/change_approved_status_product/",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data !== "Succeed!") {
            console.log("ERROR!");
          }
        })
        .catch((error) => {
          console.log("ERROR!");
        });
    };

    return (
      <div className="container" style={{ height: "auto", width: "100vw", maxWidth: "98vw", marginBottom: "10vh" }}>
        <h1 className="title has-text-centered mt-5">Check These Products</h1>
        <div className="table-container" style={{ width: "100vw" }}>
          <table className="table is-striped is-hoverable is-fullwidth" style={{maxHeight: "120vh", overflowY: "scroll"}}>
            <thead>
              <tr>
                <th style={{ width: "28vw", alignItems: "center", textAlign: "center" }}>Image + URL</th>
                <th>Title</th>
                <th>Source</th>
                <th>ASIN</th>
                <th>Price</th>
                <th>Approve ({approved})</th>
                <th>Not Approve ({notApproved})</th>
              </tr>
            </thead>
            <tbody>
              {products
                .map((product, index) => (
                  <tr key={product.url}>
                    <td style={{ alignItems: "center", textAlign: "center" }}>
                      <a
                        href={product.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="erbImage"
                          alt={product.title}
                          src={
                            product.img === ""
                              ? "/assets/noimage.png"
                              : product.img
                          }
                          style={{ width: "6vw", height: "6vw", objectFit: "contain" }}
                          loading="lazy"
                        />
                      </a>
                      ←Src AZ→
                      <img
                        className="erbImage"
                        alt={product.title}
                        src={product.similarity[0]}
                        style={{ width: "6vw", height: "6vw", objectFit: "contain", cursor: "pointer" }}
                        loading="lazy"
                        onClick={() => window.open(product.asin)}
                      />
                    </td>
                    <td>
                      <strong>{product.title.split("| At Source:")[0]}</strong>
                      <br />
                      <small>
                        <span className="has-text-danger">At Source:</span>{" "}
                        {product.title.split("| At Source:")[1].split(
                          "| Last Update"
                        )[0]}
                      </small>
                    </td>
                    <td>{product.source}</td>
                    <td>
                      <a
                        href={product.asin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {product.asin.split("/").pop()}
                      </a>
                    </td>
                    <td>
                      ${product.price.toFixed(2)}
                      <br />
                      {(product.roi * 100).toFixed(2)}%
                      <br />
                      {product.qty === -1 && (
                        <span className="has-text-danger">OOS!!</span>
                      )}
                    </td>
                    <td>
                      <button
                        className="button is-success"
                        onClick={() => {
                          products.splice(index, 1);
                          setApproved(approved + 1);
                          productSetApprove(product.url, true, product.source);
                        }}
                      >
                        Approve
                      </button>
                    </td>
                    <td>
                      <button
                        className="button is-danger"
                        onClick={() => {
                          products.splice(index, 1);
                          setNotApproved(notApproved + 1);
                          productSetApprove(product.url, false, product.source);
                        }}
                      >
                        Not Approve
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div style={{textAlign: "center"}}>
          <button onClick={() => {setPage(p => p - 1); setProducts([])}} className="button is-link">Prev Page</button>&emsp;
          {page + 1}&emsp;
          <button onClick={() => {setPage(p => p + 1); setProducts([])}} className="button is-link">Next Page</button>
        </div>
      </div>
    );
  }
};


export default Admin;
