import { useAuth0 } from "@auth0/auth0-react";

function SubscribeButtonPPG(props) {
  const {
    isAuthenticated,
    isLoading,
    user,
    loginWithRedirect,
  } = useAuth0();

  if (!isLoading && isAuthenticated) {
    return <><button onClick={() => window.location.href = `https://store.payproglobal.com/checkout?products[1][id]=${props.prd_id}&page-template=19048&language=en&currency=USD&billing-email=${user.email}${props.monthly ? '' : '&coupon-code-to-add=NEPETOANNUAL'}`} className="button is-success" style={{ fontSize: "20px", padding: "10px 25px", margin: "auto", boxShadow: "0px 0px 5px 0px gray", border: "2px solid gray" }}>{props.text}</button><br/></>;
  } else {
    return (
      <><button
        className="button is-rounded is-size-6-mobile is-size-6-tablet"
        style={{ backgroundColor: "#32CD32", margin: "auto", color: "#ffffff" }}
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              screen_hint: "signup",
            },
            appState: { returnTo: props.ultimatePlan ? "/ultimateplan": "/pricing?s=1" },
          })
        }
      >
        Sign up for Free!
      </button>{!props.ultimatePlan && <br/>}</>
    );
  }
}

export default SubscribeButtonPPG;
