import React, { useState } from 'react';
import { Loader } from './Loader';
import './AdminSubscriptionDash.css';

export const CircularLoader = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [emails, setEmails] = useState([]);

  const closeModal = () => {
    setOpenModal(false);
  };

  const percentage = props.data.allCancels.length !== 0 ? (props.data.allCancelsAndActive.length / props.data.allCancels.length) * 100 : 0;

  // Ensure percentage is between 0 and 100
  const validPercentage = Math.max(0, Math.min(100, percentage));

  // Calculate the dash array and offset for the circular progress
  const circumference = 2 * Math.PI * 45;
  const dashOffset = circumference * (1 - validPercentage / 100);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
        {/* Title */}
        <div className="loaderTitle" onClick={() => {setEmails(props.data.allCancelsAndActive); setOpenModal(true);}}>
          {props.title}
        </div>
        <div style={{ width: "100px", height: "100px", position: "relative" }}>
          {props.loading ? (
            <>
              <Loader size="large" />
            </>
          ) : (
            <>
              <svg viewBox={`0 0 100 100`} style={{ transform: 'rotate(-90deg)' }}>
                {/* Background circle */}
                <circle
                  cx={50}
                  cy={50}
                  r={45}
                  fill="transparent"
                  strokeWidth={10}
                  stroke="rgba(0,0,0,0.1)"
                />

                {/* Progress circle */}
                <circle
                  cx={50}
                  cy={50}
                  r={45}
                  fill="transparent"
                  strokeWidth={10}
                  stroke={"rgba(140,62,124,0.7)"}
                  strokeDasharray={circumference}
                  strokeDashoffset={dashOffset}
                  style={{
                    transition: 'stroke-dashoffset 0.5s ease-out',
                  }}
                />
              </svg>

              {/* Percentage text in the center */}
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: "20px",
                  color: "rgba(140,62,124,0.7)",
                  cursor: "pointer"
                }}
                onClick={() => {setEmails(props.data.allCancelsAndActive); setOpenModal(true);}}
              >
                {validPercentage.toFixed(0)}%
              </div>
            </>
          )
          }
        </div>
      </div>

      <div className={`modal ${openModal ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={closeModal}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{props.title} - Re-Subscribes - Start Date</p>
            <button
              className="delete"
              aria-label="close"
              onClick={closeModal}
            ></button>
          </header>
          <section className="modal-card-body">
            <ul>
              {emails.map((item, index) => {
                return (
                  <li key={index}>{item.email} - {new Date(item.start_date).toLocaleDateString()}</li>
                );
              })}
            </ul>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-info"
              onClick={closeModal}
            >
              Close
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};