import React, { useState } from 'react';

export const Sidebar = (props) => {

  const menuItems = [
    {
      id: 'subscriptions',
      label: 'Subscriptions',
      icon: 'fa fa-user'
    },
    {
      id: 'statistics',
      label: 'Statistics',
      icon: 'fa fa-bar-chart'
    },
    {
      id: 're-Subscribed',
      label: 'Re-Subscribed',
      icon: 'fa fa-pie-chart'
    },
    {
      id: 'remaining',
      label: 'Remaining',
      icon: 'fa fa-cog'
    }
  ];

  return (
    <div className="sidebar is-narrow" style={{
      width: '250px',
      backgroundColor: '#f5f5f5',
      height: '100vh',
      border: '1px solid #e0e0e0',
      position: "sticky"
    }}>
      <aside className="menu">
        <p className="menu-label ml-2 mt-2">
          Admin Dashboard
        </p>
        <ul className="menu-list">
          {menuItems.map((item) => (
            <li key={item.id}>
              <a
                className={props.activeItem === item.id ? 'is-active' : ''}
                onClick={() => props.setActiveItem(item.id)}
                style={{ backgroundColor: props.activeItem === item.id ? "rgba(140,62,124,0.7)" : ""}}
              >
                <span className="icon is-small mr-2" style={{ color: props.activeItem !== item.id ? "rgba(140,62,124,0.7)" : "" }}>
                  <i className={item.icon}></i>
                </span>
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </aside>
    </div>
  );
};