import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { StatisticCard } from './Components/StatisticCard';
import { Sidebar } from './Components/SideBar';
import { CircularLoader } from './Components/CircularLoader';
import { SubscriptionCardCancel } from './Components/SubscriptionCardCancel';
import { SubscriptionCardInTrial } from './Components/SubscriptionCardInTrial';
import { Remaining } from './Components/Remaining';

function AdminSubscriptionDash() {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [subscriptions, setSubscriptions] = useState([]);

  const [activeItem, setActiveItem] = useState("subscriptions");
  const [loading, setLoading] = useState(false);

  const [cancelledSince, setCancelledSince] = useState(30);

  const [password, setPassword] = useState("");
  const targetHash = '8a7400afda139446391d099a4f2c5ee373e44c9bff276cf57229df1cdb9b8b08';

  async function sha256(message) {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
  }

  const getCountTodayYesterday = (today_yesterday, software) => {
    // Get the current date
    const today = new Date();

    // Calculate the date X days ago
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    // Count items where the start_date is within the last X days
    return subscriptions.filter(item => {
      const startDate = new Date(item.start_date);
      if (today_yesterday === 'today') {
        return startDate.toISOString().split('T')[0] === today.toISOString().split('T')[0] && item.software == software && item.active;
      } else {
        return startDate.toISOString().split('T')[0] === yesterday.toISOString().split('T')[0] && item.software == software && item.active;
      }
    });
  }

  const getCountLastXDays = (days, software) => {
    // Get the current date
    const today = new Date();

    // Calculate the date X days ago
    const cutoffDate = new Date();
    cutoffDate.setDate(today.getDate() - days);

    // Count items where the start_date is within the last X days
    return subscriptions.filter(item => {
      const startDate = new Date(item.start_date);
      return startDate >= cutoffDate && startDate <= today && item.software == software && item.active;
    });
  }
  function getCountForLastMonth(software) {
    // Get the current date
    const today = new Date();

    // Calculate last month and handle year roll-over
    let lastMonth = today.getMonth(); // 0 = January, so no need to add 1
    let year = today.getFullYear();

    if (lastMonth === 0) { // If current month is January, last month is December of the previous year
      lastMonth = 11;
      year -= 1;
    } else {
      lastMonth -= 1;
    }

    // Count items for last month
    return subscriptions.filter(item => {
      const startDate = new Date(item.start_date);
      return startDate.getMonth() === lastMonth && startDate.getFullYear() === year && item.software == software && item.active;
    });
  }
  function getCountForThisMonth(software) {
    // Get the current date
    const today = new Date();

    // Get the current month and year
    const currentMonth = today.getMonth(); // 0 = January
    const currentYear = today.getFullYear();

    // Count items for this month
    return subscriptions.filter(item => {
      const startDate = new Date(item.start_date);
      return startDate.getMonth() === currentMonth && startDate.getFullYear() === currentYear && item.software == software && item.active;
    });
  }
  function getCountSubscriptionsBySoftware(software) {
    return subscriptions.filter(item => item.software == software && item.active);
  }

  function getCancelledFromPlan(plan) {
    const today = new Date();
    const cutoffDate = new Date();
    cutoffDate.setDate(today.getDate() - cancelledSince);

    const cancelSubscriptions = subscriptions.filter(item => {
      const startDate = new Date(item.start_date);
      return startDate >= cutoffDate && item.plan == plan && !item.active;
    });

    return cancelSubscriptions.map(item => {
      const trialOffDate = new Date(item.start_date);
      trialOffDate.setDate(trialOffDate.getDate() + 14); // Correctly add 14 days to start_date
      item.wasOnTrial = trialOffDate > new Date(item.cancel_date) && item.had_trial;
      return item;
    });
  }
  function getCancelledTotal() {
    const today = new Date();
    const cutoffDate = new Date();
    cutoffDate.setDate(today.getDate() - cancelledSince);

    const cancelSubscriptions = subscriptions.filter(item => {
      const startDate = new Date(item.start_date);
      return startDate >= cutoffDate && !item.active;
    });

    return cancelSubscriptions.map(item => {
      const trialOffDate = new Date(item.start_date);
      trialOffDate.setDate(trialOffDate.getDate() + 14); // Correctly add 14 days to start_date
      item.wasOnTrial = trialOffDate > new Date(item.cancel_date) && item.had_trial;
      return item;
    });
  }

  function getInTrialFromPlan(plan) {
    const today = new Date();
    const cutoffDate = new Date();
    cutoffDate.setDate(today.getDate() - 14);

    return subscriptions.filter(item => {
      const startDate = new Date(item.start_date);
      return startDate >= cutoffDate && startDate <= today && item.plan == plan && item.had_trial && item.active;
    }).map(item => { return { email: item.email, date: item.start_date } });
  }
  function getInTrialTotal() {
    const today = new Date();
    const cutoffDate = new Date();
    cutoffDate.setDate(today.getDate() - 14);

    return subscriptions.filter(item => {
      const startDate = new Date(item.start_date);
      return startDate >= cutoffDate && startDate <= today && item.had_trial && item.active;
    }).map(item => { return { email: item.email, date: item.start_date } });
  }

  function getReSubscribedData(software) {
    const cancel_date_count = subscriptions.filter(item => item.software === software && item.cancel_date);
    const active_count = subscriptions.filter(item => item.software === software && item.cancel_date && item.active);
    return { allCancels: cancel_date_count, allCancelsAndActive: active_count };
  }

  useEffect(() => {
    const getAllSubscriptions = async () => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      fetch("https://server.nepeto.com/sd_user_get_all_subscriptions/", {
        mode: "cors",
        headers: { Authorization: `Bearer ${accessToken}` },
      }).then((response) => response.json())
        .then((data) => {
          setSubscriptions(data);
          setLoading(false);
        }).catch(e => {
          console.log(e);
          setLoading(false);
        });
    };

    if (!isLoading && isAuthenticated && password === targetHash) {
      getAllSubscriptions();
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated, password]);

  if (password !== targetHash) {
    return <div style={{ height: "80vh" }}>
      <input onChange={async (e) => setPassword(await sha256(e.target.value))} placeholder="Pass" />
    </div>
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", }}>
      <Sidebar activeItem={activeItem} setActiveItem={setActiveItem} />

      <div style={{ margin: "auto", marginTop: "0" }}>
        <div className="container" style={{ width: "calc(100vw - 250px" }}>
          <h1 className="title is-3 my-5 has-text-centered" style={{ color: "rgb(140,62,124)" }}>{activeItem.charAt(0).toUpperCase() + activeItem.slice(1)}</h1>
          {activeItem === "subscriptions" && (
            <div className="columns is-multiline">
              <div className="column is-3">
                <StatisticCard
                  class={"statisticCardNepeto"}
                  loading={loading}
                  fill={"rgba(140,62,124,0.7)"}
                  softwareName={"Nepeto"}
                  subscriptionData={{
                    today: getCountTodayYesterday("today", "Nepeto"),
                    yesterday: getCountTodayYesterday("yesterday", "Nepeto"),
                    lastWeek: getCountLastXDays(7, "Nepeto"),
                    thisMonth: getCountForThisMonth("Nepeto"),
                    lastMonth: getCountForLastMonth("Nepeto"),
                    total: getCountSubscriptionsBySoftware("Nepeto")
                  }}
                />
              </div>
              <div className="column is-3">
                <StatisticCard
                  class={"statisticCardNepeto"}
                  loading={loading}
                  fill={"rgba(140,62,124,0.7)"}
                  softwareName={"RaExtApp"}
                  subscriptionData={{
                    today: getCountTodayYesterday("today", "RaExtApp"),
                    yesterday: getCountTodayYesterday("yesterday", "RaExtApp"),
                    lastWeek: getCountLastXDays(7, "RaExtApp"),
                    thisMonth: getCountForThisMonth("RaExtApp"),
                    lastMonth: getCountForLastMonth("RaExtApp"),
                    total: getCountSubscriptionsBySoftware("RaExtApp")
                  }}
                />
              </div>
              <div className="column is-3">
                <StatisticCard
                  class={"statisticCardMarter"}
                  loading={loading}
                  fill={"rgba(230,175,10,0.7)"}
                  softwareName={"Marter"}
                  subscriptionData={{
                    today: getCountTodayYesterday("today", "Marter"),
                    yesterday: getCountTodayYesterday("yesterday", "Marter"),
                    lastWeek: getCountLastXDays(7, "Marter"),
                    thisMonth: getCountForThisMonth("Marter"),
                    lastMonth: getCountForLastMonth("Marter"),
                    total: getCountSubscriptionsBySoftware("Marter")
                  }}
                />
              </div>
              <div className="column is-3">
                <StatisticCard
                  class={"statisticCardMarter"}
                  loading={loading}
                  fill={"rgba(230,175,10,0.7)"}
                  softwareName={"MOA"}
                  subscriptionData={{
                    today: getCountTodayYesterday("today", "MOA"),
                    yesterday: getCountTodayYesterday("yesterday", "MOA"),
                    lastWeek: getCountLastXDays(7, "MOA"),
                    thisMonth: getCountForThisMonth("MOA"),
                    lastMonth: getCountForLastMonth("MOA"),
                    total: getCountSubscriptionsBySoftware("MOA")
                  }}
                />
              </div>
            </div>
          )}

          {activeItem === "statistics" && (
            <div className="columns is-multiline">
              <div className="column is-5 is-offset-1">
                Cancelled from <select onChange={(e) => setCancelledSince(e.target.value)} value={cancelledSince}>
                  <option value="30">30 Days</option>
                  <option value="60">60 Days</option>
                  <option value="90">90 Days</option>
                  <option value="180">180 Days</option>
                  <option value="365">365 Days</option>
                  <option value="9999">All Time</option>
                </select> days ago
                <SubscriptionCardCancel
                  loading={loading}
                  data={[
                    { name: "Nepeto Master", data: getCancelledFromPlan("Master") },
                    { name: "Nepeto Pro", data: getCancelledFromPlan("Pro") },
                    { name: "Nepeto Advanced", data: getCancelledFromPlan("Advanced") },
                    { name: "Starter", data: getCancelledFromPlan("Starter") },
                    { name: "Nepeto App & Chrome", data: getCancelledFromPlan("RaExtApp") },
                    { name: "Marter", data: getCancelledFromPlan("Marter") },
                    { name: "MOA", data: getCancelledFromPlan("MOA") },
                    { name: "Total", data: getCancelledTotal() }
                  ]}
                />
              </div>
              <div className="column is-5">
                .
                <SubscriptionCardInTrial
                  loading={loading}
                  data={[
                    { name: "Nepeto Master", data: getInTrialFromPlan("Master") },
                    { name: "Nepeto Pro", data: getInTrialFromPlan("Pro") },
                    { name: "Nepeto Advanced", data: getInTrialFromPlan("Advanced") },
                    { name: "Starter", data: getInTrialFromPlan("Starter") },
                    { name: "Nepeto App & Chrome", data: getInTrialFromPlan("RaExtApp") },
                    { name: "Marter", data: getInTrialFromPlan("Marter") },
                    { name: "MOA", data: getInTrialFromPlan("MOA") },
                    { name: "Total", data: getInTrialTotal() }
                  ]}
                />
              </div>
            </div>
          )}

          {activeItem === "re-Subscribed" && (
            <div className="columns is-multiline mt-6">
              <div className="column is-3 mb-5">
                <CircularLoader loading={loading} data={getReSubscribedData("Nepeto")} title={"Nepeto"} />
              </div>
              <div className="column is-3 mb-5">
                <CircularLoader loading={loading} data={getReSubscribedData("RaExtApp")} title={"Nepeto App & Chrome"} />
              </div>
              <div className="column is-3 mb-5">
                <CircularLoader loading={loading} data={getReSubscribedData("Marter")} title={"Marter"} />
              </div>
              <div className="column is-3 mb-5">
                <CircularLoader loading={loading} data={getReSubscribedData("MOA")} title={"MOA"} />
              </div>
            </div>
          )}

          {activeItem === "remaining" && (
            <Remaining subscriptions={subscriptions} loading={loading} />
          )}

        </div>
      </div>
    </div>
  );
}

export default AdminSubscriptionDash;
