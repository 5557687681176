import React, { useState } from 'react';

export const Remaining = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [emails, setEmails] = useState([]);
  const [title, setTitle] = useState("");

  const closeModal = () => {
    setOpenModal(false);
  };

  function monthsBetweenDates(date1, date2) {
    // Ensure the dates are in Date format
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Calculate the difference in years and months
    const yearsDiff = d2.getFullYear() - d1.getFullYear();
    const monthsDiff = d2.getMonth() - d1.getMonth();

    // Combine years and months into total months
    return yearsDiff * 12 + monthsDiff;
}

  function calculateMonthlySums(monthsAgo, plan, active) {
    const today = new Date();

    return props.subscriptions.filter(item => {
      if (active && !item.active) return false;
      if (item.plan !== plan) return false; // Only consider current plan

      const startDate = new Date(item.start_date);

      // Check if the item's start date is within the valid range
      return monthsBetweenDates(startDate, item.cancel_date ? new Date(item.cancel_date) : today) >= monthsAgo;
    });
  }

  function calculateMonthlySumsTotal(monthsAgo, active) {
    const today = new Date();
    const currentMonth = today.getMonth() + 1; // getMonth() returns 0-11, so add 1 to make it 1-12

    // Calculate the threshold month and year
    let thresholdMonth = currentMonth - monthsAgo;
    let thresholdYear = today.getFullYear();

    if (thresholdMonth <= 0) {
      thresholdMonth += 12;
      thresholdYear -= 1;
    }

    return props.subscriptions.filter(item => {
      if (active && !item.active) return false; // Only consider active items

      const startDate = new Date(item.start_date);
      const startMonth = startDate.getMonth() + 1;
      const startYear = startDate.getFullYear();

      // Check if the item's start date is within the valid range
      return (
        startYear > thresholdYear ||
        (startYear === thresholdYear && startMonth <= thresholdMonth)
      );
    });
  }

  const plans = [
    { name: "Nepeto Master", value: "Master" },
    { name: "Nepeto Pro", value: "Pro" },
    { name: "Nepeto Advanced", value: "Advanced" },
    { name: "Starter", value: "Starter" },
    { name: "Nepeto App & Chrome", value: "RaExtApp" },
    { name: "Marter", value: "Marter" },
    { name: "MOA", value: "MOA" },
  ]

  return (
    <>
      <div className="card">
        <header className="card-header">
          <p className={`card-header-title ${props.class}`}>
            Remainings Details
          </p>
        </header>
        <div className="card-content">
          <div className="content">
            <table className="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>Plan</th>
                  <th style={{ textAlign: 'center', }}>Last 1 Month</th>
                  <th style={{ textAlign: 'center', }}>Last 2 Months</th>
                  <th style={{ textAlign: 'center', }}>Last 3 Months</th>
                  <th style={{ textAlign: 'center', }}>Last 4 Months</th>
                  <th style={{ textAlign: 'center', }}>Last 5 Months</th>
                  <th style={{ textAlign: 'center', }}>Last 6 Months</th>
                </tr>
              </thead>
              <tbody>
                {plans.map((item) => {
                  const name = item.name;
                  const plan = item.value;

                  const month1Active = calculateMonthlySums(0, plan, true);
                  const month2Active = calculateMonthlySums(1, plan, true);
                  const month3Active = calculateMonthlySums(2, plan, true);
                  const month4Active = calculateMonthlySums(3, plan, true);
                  const month5Active = calculateMonthlySums(4, plan, true);
                  const month6Active = calculateMonthlySums(5, plan, true);
                  const month1Count = calculateMonthlySums(0, plan, false);
                  const month2Count = calculateMonthlySums(1, plan, false);
                  const month3Count = calculateMonthlySums(2, plan, false);
                  const month4Count = calculateMonthlySums(3, plan, false);
                  const month5Count = calculateMonthlySums(4, plan, false);
                  const month6Count = calculateMonthlySums(5, plan, false);

                  return (
                    <tr>
                      <td>{name}</td>
                      <td style={{ textAlign: 'center', }}>
                        <span style={{ cursor: "pointer", }} onClick={() => { setEmails(month1Count.filter(item => !month1Active.includes(item))); setTitle("Today's"); setOpenModal(true) }}>
                          {month1Active.length} / {month1Count.length}
                        </span>
                      </td>
                      <td style={{ textAlign: 'center', }}>
                        <span style={{ cursor: "pointer" }} onClick={() => { setEmails(month2Count.filter(item => !month2Active.includes(item))); setTitle("Today's"); setOpenModal(true) }}>
                          {month2Active.length} / {month2Count.length}
                        </span>
                      </td>
                      <td style={{ textAlign: 'center', }}>
                        <span style={{ cursor: "pointer" }} onClick={() => { setEmails(month3Count.filter(item => !month3Active.includes(item))); setTitle("Today's"); setOpenModal(true) }}>
                          {month3Active.length} / {month3Count.length}
                        </span>
                      </td>
                      <td style={{ textAlign: 'center', }}>
                        <span style={{ cursor: "pointer" }} onClick={() => { setEmails(month4Count.filter(item => !month4Active.includes(item))); setTitle("Today's"); setOpenModal(true) }}>
                          {month4Active.length} / {month4Count.length}
                        </span>
                      </td>
                      <td style={{ textAlign: 'center', }}>
                        <span style={{ cursor: "pointer" }} onClick={() => { setEmails(month5Count.filter(item => !month5Active.includes(item))); setTitle("Today's"); setOpenModal(true) }}>
                          {month5Active.length} / {month5Count.length}
                        </span>
                      </td>
                      <td style={{ textAlign: 'center', }}>
                        <span style={{ cursor: "pointer" }} onClick={() => { setEmails(month6Count.filter(item => !month6Active.includes(item))); setTitle("Today's"); setOpenModal(true) }}>
                          {month6Active.length} / {month6Count.length}
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td>Total</td>
                  {[0, 1, 2, 3, 4, 5].map((num, index) => {
                    const actives = calculateMonthlySumsTotal(num, true);
                    const counts = calculateMonthlySumsTotal(num, false);
                    return (
                      <td key={index} style={{ textAlign: 'center', }}>
                        <span style={{ cursor: "pointer", }} onClick={() => { setEmails(counts.filter(item => !actives.includes(item))); setTitle("Today's"); setOpenModal(true) }}>
                          {actives.length} / {counts.length}
                        </span>
                      </td>
                    )
                  })}

                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div className={`modal ${openModal ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={closeModal}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{title} new Subscribers - Start Date</p>
            <button
              className="delete"
              aria-label="close"
              onClick={closeModal}
            ></button>
          </header>
          <section className="modal-card-body">
            <ul>
              {emails.map((item, index) => {
                return (
                  <li key={index}>{item.email} - {new Date(item.start_date).toLocaleDateString()}</li>
                );
              })}
            </ul>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-info"
              onClick={closeModal}
            >
              Close
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};