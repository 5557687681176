import React, { useMemo, useState } from 'react';
import { Loader } from './Loader';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


export const StatisticCard = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [emails, setEmails] = useState([]);
  const [title, setTitle] = useState("");

  const closeModal = () => {
    setOpenModal(false);
  };

  const monthlyCounts = useMemo(() => {
    // Get the current date
    const now = new Date();

    // Generate an array of the last 12 months (including current month)
    const monthLabels = Array.from({ length: 12 }, (_, i) => {
      const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
      const formattedDate = new Intl.DateTimeFormat('en-US', { month: 'short', year: '2-digit' }).format(date);
      return formattedDate;
    }).reverse();

    // Count signups for each month
    const monthlyCounts = monthLabels.map(monthLabel => {
      const [month, year] = monthLabel.split(' ');
      const monthIndex = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ].indexOf(month);

      const fullYear = parseInt('20' + year);

      const count = props.subscriptionData.total.filter(item => {
        const startDate = new Date(item.start_date);
        return startDate.getFullYear() === fullYear &&
          startDate.getMonth() === monthIndex;
      }).length;

      return {
        month: monthLabel,
        signups: count
      };
    });

    return monthlyCounts;
  }, [props.subscriptionData.total]);

  return (
    <>
      <div className="card">
        <header className="card-header">
          <p className={`card-header-title ${props.class}`}>
            {props.softwareName} Subscriptions
          </p>
        </header>
        <div className="card-content">
          <div className="content">
            <table className="table is-fullwidth is-striped">
              <tbody>
                <tr>
                  <td>Today:</td>
                  <td className={`has-text-weight-bold ${props.subscriptionData.today.length > 0 ? "has-text-success" : "has-text-danger"}`}>
                    {props.loading ? (
                      <Loader />
                    ) : (
                      <span style={{ cursor: "pointer" }} onClick={() => { setEmails(props.subscriptionData.today); setTitle("Today's"); setOpenModal(true) }}>
                        +{props.subscriptionData.today.length} New
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Yesterday:</td>
                  <td className={`has-text-weight-bold ${props.subscriptionData.yesterday.length > 0 ? "has-text-success" : "has-text-danger"}`}>
                    {props.loading ? (
                      <Loader />
                    ) : (
                      <span style={{ cursor: "pointer" }} onClick={() => { setEmails(props.subscriptionData.yesterday); setTitle("Yesterday's"); setOpenModal(true) }}>
                        +{props.subscriptionData.yesterday.length} New
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Last 7 days:</td>
                  <td className={`has-text-weight-bold ${props.subscriptionData.lastWeek.length > 0 ? "has-text-success" : "has-text-danger"}`}>
                    {props.loading ? (
                      <Loader />
                    ) : (
                      <span style={{ cursor: "pointer" }} onClick={() => { setEmails(props.subscriptionData.lastWeek); setTitle("Last Week's"); setOpenModal(true) }}>
                        +{props.subscriptionData.lastWeek.length} New
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>This Month:</td>
                  <td className={`has-text-weight-bold ${props.subscriptionData.thisMonth.length > 0 ? "has-text-success" : "has-text-danger"}`}>
                    {props.loading ? (
                      <Loader />
                    ) : (
                      <span style={{ cursor: "pointer" }} onClick={() => { setEmails(props.subscriptionData.thisMonth); setTitle("This Month's"); setOpenModal(true) }}>
                        +{props.subscriptionData.thisMonth.length} New
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Last Month:</td>
                  <td className={`has-text-weight-bold ${props.subscriptionData.lastMonth.length > 0 ? "has-text-success" : "has-text-danger"}`}>
                    {props.loading ? (
                      <Loader />
                    ) : (
                      <span style={{ cursor: "pointer" }} onClick={() => { setEmails(props.subscriptionData.lastMonth); setTitle("Last Month's"); setOpenModal(true) }}>
                        +{props.subscriptionData.lastMonth.length} New
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <footer className="card-footer">
          <div className="card-footer-item has-text-centered">
            Total Subscribers: <div className="has-text-weight-bold ml-2">
              {props.loading ? (
                <Loader />
              ) : (
                <span style={{ cursor: "pointer" }} onClick={() => { setEmails(props.subscriptionData.total); setTitle("Total"); setOpenModal(true) }}>
                  {props.subscriptionData.total.length}
                </span>
              )}
            </div>
          </div>
        </footer>
      </div>

      <div className="card mt-2">
        <div className="card-content" style={{ height: '300px' }}>
          <ResponsiveContainer width="125%" height="100%" style={{ marginLeft: "-20%" }}>
            <BarChart
              data={monthlyCounts}
              margin={{ top: 10, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="signups" fill={props.fill} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className={`modal ${openModal ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={closeModal}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{title} new Subscribers - Start Date</p>
            <button
              className="delete"
              aria-label="close"
              onClick={closeModal}
            ></button>
          </header>
          <section className="modal-card-body">
            {JSON.stringify(emails.reduce((counts, { plan }) => {
              counts[plan] = (counts[plan] || 0) + 1;
              return counts;
            }, {}))}<br/>--------------<br/>
            <ul>
              {emails.map((item, index) => {
                return (
                  <li key={index}>{item.email} - {new Date(item.start_date).toLocaleDateString()} - {item.plan}</li>
                );
              })}
            </ul>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-info"
              onClick={closeModal}
            >
              Close
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};