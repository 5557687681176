import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { useAuth0 } from '@auth0/auth0-react';
import ProductsTable from '../StorefrontScanner/StorefrontProductsTable';
import { Helmet } from 'react-helmet';
import StorefrontFilterBar from '../StorefrontScanner/StorefrontFilterBar';

const ProductFinder = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [fileName, setFileName] = useState(null);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [sortASC, setSortASC] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const CATEGORY_BSR_COUNT = {
    'Kindle Store': 6756566, 'Video Shorts': 127580, 'Apps & Games': 936100, 'Baby Products': 4172515,
    'Digital Music': 68941656, 'Alexa Skills': 112529, 'Toys & Games': 9204843,
    'Patio, Lawn & Garden': 22608266, 'Books': 102997339, 'Arts, Crafts & Sewing': 16296162,
    'Software': 177161, 'Sports & Outdoors': 41416624, 'Gift Cards': 28409, 'Video Games': 1204647,
    'Handmade Products': 3644844, 'Clothing, Shoes & Jewelry': 275101033, 'Office Products': 12266328,
    'Grocery & Gourmet Food': 3869382, 'Tools & Home Improvement': 37997159, 'Movies & TV': 7740340,
    'Musical Instruments': 2893522, 'Collectibles & Fine Art': 8802597, 'Appliances': 1565048,
    'Pet Supplies': 8652837, 'Cell Phones & Accessories': 26169713, 'Baby': 4172515,
    'Industrial & Scientific': 20388882, 'Everything Else': 8317014, 'CDs & Vinyl': 8366789,
    'Beauty & Personal Care': 14890600, 'Home & Kitchen': 161989558, 'Electronics': 25856893,
    'Automotive': 46255283, 'Magazine Subscriptions': 42578, 'Health & Household': 11049107,
    'Audible Books & Originals': 743380, 'Kitchen & Dining': 37818304, "Amazon Devices": 1894,
    "Computers & Accessories": 14064999, "Camera & Photo Products": 4021127,
    "Collectible Coins": 210648, "Camera & Photo": 4021127
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  function removeDuplicates(list) {
    var uniqueObjects = {};
    var result = [];

    list.forEach(function (obj) {
      var key = obj.asin + obj.source_name;

      if (!uniqueObjects[key]) {
        uniqueObjects[key] = true;
        result.push(obj);
      }
    });

    return result;
  }

  const sendObj = async (asins) => {
    const chunkSize = 1000;
    const products = [];
    const chunkArray = (array, size) => {
      const chunks = [];
      for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
      }
      return chunks;
    };
  
    const asinsChunks = chunkArray(asins, chunkSize);
    
    for (const chunk of asinsChunks) {
      const accessToken = await getAccessTokenSilently();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json; charset=utf8",
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(chunk),
      };

      await fetch("https://server.nepeto.com/mastersearch/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data === "No permissions") {
            alert("You must be a Master plan subscriber to use the Nepeto Master Search!")
          } else {
            products.push(...data['products']);
          }
        })
        .catch((error) => {
          alert("Error. Please try again.")
        });
    }

    setProducts(products.length > 0 ? products : 0)
  };

  async function uploadCSV(file) {
    if (props.activePlanLevel !== "Master Plan") {
      alert("You must be a Master plan subscriber to use the Nepeto Master Search!")
    } else {
      const lastClickTimestamp = localStorage.getItem('lastClickTimestamp') || -1;
      const minutesSinceLastClick = (Date.now() - parseInt(lastClickTimestamp)) / (1000 * 60);
      if (minutesSinceLastClick < 2 && lastClickTimestamp !== -1) {
        alert(`Please wait ${((2 - minutesSinceLastClick) * 60).toFixed(0)} seconds before scanning again!`);
        window.location.reload();
        return
      }
      localStorage.setItem('lastClickTimestamp', Date.now());
      setFileName("");
      if (file.size > 10000000) {
        alert(
          "Your file is larger than 10MB. Please consider splitting the Excel file and sending multiple scan requests."
        );
      } else {
        if (file) {
          const reader = new FileReader();

          reader.onload = function (e) {
            const fileName = file.name.toLowerCase();
            if (fileName.endsWith('.csv')) {
              const text = e.target.result;
              let res = []
              Papa.parse(text, {
                header: false,
                complete: (results) => {
                  res += results.data;
                },
              });
              sendObj(res.replace("ASIN,", "").split(","))
            }
          };

          if (file.name.toLowerCase().endsWith('.csv')) {
            reader.readAsText(file);
          }
        }
      }
    }
  }

  useEffect(() => {
    function calculateROI(item, settings) {
      let logisticsCost = parseFloat(localStorage.getItem("costPrep") || 0);
      let price = item.price * (1 - ((settings.priceCoupon || 0) / 100));
      let azPrice = item.az_price;
      let fbaCost = settings.isFBM ? 0 : item.fba_cost;
      let sellingOnAzFees = item.selling_on_az_fees;

      return (azPrice - price - fbaCost - logisticsCost - sellingOnAzFees) / (price + logisticsCost);
    }

    function filterData(data, filters) {
      return data.filter(item => {
        if (parseInt(filters.mustBB || "0") !== 0 && item.az_offers && !item.az_offers.includes('"is_buy_box_winner": true')) {
          return false;
        }

        // mustNotBB
        if (parseInt(filters.mustNotBB || "0") !== 0 && item.az_offers && item.az_offers.includes('"is_buy_box_winner": true')) {
          return false;
        }

        // showFeesNA
        if (parseInt(filters.showFeesNA || "0") === 0 && (item.fba_cost === 0 || item.selling_on_az_fees === 0)) {
          if (item.az_price > 0) {
            return false;
          }
        }

        // amazonNotSeller
        if (parseInt(filters.amazonNotSeller || "0") !== 0) {
          if (item.az_offers && (item.az_offers.includes("ATVPDKIKX0DER") || item.az_offers.includes("A2R2RITDJNW1Q6"))) {
            return false;
          }
        }

        // top_bsr
        if (filters.top_bsr && (filters.top_bsr[0] !== -1 || filters.top_bsr[1] !== -1)) {
          let categoryCount = CATEGORY_BSR_COUNT[item.category] || 10000000;
          let salesRankPercentage = (item.sales_rank / categoryCount) * 100;

          if (filters.top_bsr[0] !== -1 && salesRankPercentage < filters.top_bsr[0]) {
            return false;
          }

          if (filters.top_bsr[1] !== -1 && salesRankPercentage > filters.top_bsr[1]) {
            return false;
          }
        }

        // profit
        if (filters.profit && (filters.profit[0] !== -1 || filters.profit[1] !== -1)) {
          let profitValue = item.az_price - (item.price * (1 - ((filters.priceCoupon || 0) / 100))) - (filters.isFBM ? 0 : item.fba_cost) - item.selling_on_az_fees - parseFloat(localStorage.getItem("costPrep") || 0);

          if (filters.profit[0] !== -1 && profitValue < filters.profit[0]) {
            return false;
          }

          if (filters.profit[1] !== -1 && profitValue > filters.profit[1]) {
            return false;
          }
        }

        // search
        if (filters.search && filters.search !== "") {
          let searchLower = filters.search.toLowerCase();
          if (!item.title.toLowerCase().includes(searchLower) && !item.asin.includes(filters.search) && !item.url.toLowerCase().includes(searchLower)) {
            return false;
          }
        }

        // category
        if (filters.category && filters.category !== "Category" && !filters.category.includes(item.category)) {
          return false;
        }

        // qty, moq, bsr
        for (let filterAttr of ["qty", "moq", "bsr"]) {
          if (filters[filterAttr] && (filters[filterAttr][0] !== -1 || filters[filterAttr][1] !== -1)) {
            let value = item[filterAttr === "bsr" ? "sales_rank" : filterAttr];

            if (filters[filterAttr][0] !== -1 && value < filters[filterAttr][0]) {
              return false;
            }

            if (filters[filterAttr][1] !== -1 && value > filters[filterAttr][1]) {
              return false;
            }
          }
        }

        // price
        if (filters.price && (filters.price[0] !== -1 || filters.price[1] !== -1)) {
          let price = item.price * (1 - ((filters.priceCoupon || 0) / 100));

          if (filters.price[0] !== -1 && price < filters.price[0]) {
            return false;
          }

          if (filters.price[1] !== -1 && price > filters.price[1]) {
            return false;
          }
        }

        // roi
        if (filters.roi && (filters.roi[0] !== -1 || filters.roi[1] !== -1)) {
          let roi = calculateROI(item, filters);

          if (filters.roi[0] !== -1 && roi < (filters.roi[0] / 100)) {
            return false;
          }

          if (filters.roi[1] !== -1 && roi > (filters.roi[1] / 100)) {
            return false;
          }
        }

        // offers
        if (filters.offers && (filters.offers[0] !== -1 || filters.offers[1] !== -1)) {
          let offersCount = (item.az_offers.match(/{/g) || []).length;

          if (filters.offers[0] !== -1 && offersCount < filters.offers[0]) {
            return false;
          }

          if (filters.offers[1] !== -1 && offersCount > filters.offers[1]) {
            return false;
          }
        }

        // fbaoffers
        if (filters.fbaoffers && (filters.fbaoffers[0] !== -1 || filters.fbaoffers[1] !== -1)) {
          let fbaOffersCount = (item.az_offers.match(/"is_fba": true/g) || []).length;

          if (filters.fbaoffers[0] !== -1 && fbaOffersCount < filters.fbaoffers[0]) {
            return false;
          }

          if (filters.fbaoffers[1] !== -1 && fbaOffersCount > filters.fbaoffers[1]) {
            return false;
          }
        }

        // fbmoffers
        if (filters.fbmoffers && (filters.fbmoffers[0] !== -1 || filters.fbmoffers[1] !== -1)) {
          let fbmOffersCount = (item.az_offers.match(/"is_fba": false/g) || []).length;

          if (filters.fbmoffers[0] !== -1 && fbmOffersCount < filters.fbmoffers[0]) {
            return false;
          }

          if (filters.fbmoffers[1] !== -1 && fbmOffersCount > filters.fbmoffers[1]) {
            return false;
          }
        }

        // sourceName
        if (filters.sourceName && filters.sourceName !== "Supplier Name" && !filters.sourceName.includes(item.source_name)) {
          return false;
        }

        // supplierTopMinPurchase
        if (filters.supplierTopMinPurchase && parseFloat(filters.supplierTopMinPurchase) < parseFloat(item.source.minimum_order)) {
          return false;
        }

        // supplierAimAmazon
        if (filters.supplierAimAmazon && item.upc !== null) {
          return false;
        }

        return true;
      });
    }

    const sortFunction = (a, b) => {
      if (filters.sortBy) {
        let getKey = (n, key) => {
          if (filters.sortBy[0] === "roi") {
            return parseFloat(calculateROI(n, filters))
          }
          if (filters.sortBy[0] === "profit") {
            return parseFloat(n.az_price - (n.price * (1 - ((filters.priceCoupon || 0) / 100))) - (filters.isFBM ? 0 : n.fba_cost) - n.selling_on_az_fees - parseFloat(localStorage.getItem("costPrep") || 0))
          }
          return parseFloat(n[key])
        }
        if (filters.sortBy[1]) {
          return getKey(a, filters.sortBy[0]) - getKey(b, filters.sortBy[0])
        } else {
          return getKey(b, filters.sortBy[0]) - getKey(a, filters.sortBy[0])
        }
      } else {
        return 1
      }
    }

    setPage(1);
    setFilteredData(filterData(removeDuplicates(products), filters).sort((a, b) => sortFunction(a, b)));
  }, [filters])

  return (<>
    <Helmet>
      <title>Nepeto - Master Search</title>
      <meta
        name="description"
        content="Nepeto Master Search! Search for profitable products to resell on Amazon."
      />
      <meta
        name="keywords"
        content="amazon fba, product sourcing, Master Search, amazon fba products, fba products, fba sourcing"
      />
    </Helmet>
    <div style={{ justifyContent: "center", alignItems: "center", padding: "40px", minHeight: "80vh" }}>
      <h1 style={{ textAlign: "center", color: "#8B3C7E", fontSize: "230%", fontWeight: "500" }}>Nepeto Master Search</h1><br />
      {/* <button onClick={() => setSavedFilters(true)} className="button" style={{ textAlign: "center", color: "white", backgroundColor: "#8B3C7E", fontSize: "120%", fontWeight: "500", borderRadius: "50px", margin: "auto", cursor: "pointer", display: "block" }}>History Searches</button> */}
      {products.length === 0 ? <div style={{ margin: "auto", marginLeft: "auto", width: !props.isMobile && "35vw" }}>
        <h1 style={{ textAlign: "center", color: "#8B3C7E", fontSize: "150%", fontWeight: "500" }}>Step 1.</h1>
        <iframe width="100%" height="315" src="https://www.youtube.com/embed/RkiSx6tlkOs?si=Ar1RSSuN3Ul-KsqU&rel=0&autoplay=1"
          frameborder="0"
          allow="fullscreen; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
        <br /><br />
        <h1 style={{ textAlign: "center", color: "#8B3C7E", fontSize: "150%", fontWeight: "500" }}>Step 2.</h1>
        <div style={{ alignItems: "center", textAlign: "center", backgroundColor: "rgba(200,200,200,0.2)", borderRadius: "30px", marginTop: "1vh", padding: "3vh 1vw" }}>
            <h2 style={{ fontSize: "150%", marginBottom: "2vh" }}>Upload the CSV file<br />Make sure you only include the 'ASIN' column!<br/>
            <h3 style={{ fontSize: "75%", marginBottom: "2vh" }}>No limit on ASINs, average load time: 2 minutes.</h3></h2>
            <div
              class="file has-name is-link is-boxed is-centered"
              style={{ margin: "auto" }}
            >
              <label class="file-label">
                <input
                  class="file-input"
                  accept=".csv"
                  type="file"
                  name="resume"
                  onChange={(e) => {
                    uploadCSV(e.target.files[0]);
                  }}
                />
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fa fa-upload" aria-hidden="true"></i>
                  </span>
                  <span class="file-label">
                    Choose a file…
                    <br />
                    <strong style={{ fontSize: "60%", color: "lightgray" }}>
                      Accepts .csv .xlsx files
                    </strong>
                  </span>
                </span>
                <span class="file-name" style={{backgroundColor: "white"}}>
                  {fileName !== null ? (
                    fileName === "" ? (
                      <progress
                        style={{ marginTop: "6px", width: "50%" }}
                        class="progress is-link is-small"
                        max="100"
                      />
                    ) : (
                      fileName
                    )
                  ) : (
                    "No file uploaded"
                  )}
                </span>
              </label>
            </div></div><br /><br />
      </div> : products === 0 ? <h1 style={{margin: "auto", textAlign: "center"}}>No products matched your search criteria.<br />Please refresh the page and try again!</h1> :
        <>
          {(filteredData.length === 0 && Object.keys(filters).length > 0) && <p style={{textAlign: "center"}}>No products match your filters.</p>}
          <StorefrontFilterBar data={removeDuplicates(products)} setFilters={setFilters} filters={filters} activePlanLevel={"Master Plan"} showAdv={true} suppliersList={Array.from(new Set(removeDuplicates(products).map(prod => prod.source_name)))} />
          <ProductsTable
            data={(filteredData && filteredData.length > 0) ? filteredData : removeDuplicates(products)}
            supplierType={"storefront"}
            showAdv={true}
            isMobile={props.isMobile}
            hideCredits={props.hideCredits}
            filters={filters}
            setSortASC={setSortASC}
            sortASC={sortASC}
            setFilters={setFilters}
            sellerId={props.sellerId}
            refreshToken={props.refreshToken}
            setPage={setPage}
            page={page}
          />
        </>}
    </div>
  </>
  );
};

export default ProductFinder;
