import React, { useState } from 'react';
import { Loader } from './Loader';

export const SubscriptionCardCancel = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [emails, setEmails] = useState([]);

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="card">
      <header className="card-header statisticsCancel">
        <p className="card-header-title">Cancelled</p>
      </header>
      <div className="card-content">
        <table className="table is-fullwidth is-hoverable">
          <tbody>
            {props.data.map((plan, index) => {
              return (
                <tr key={index}>
                  <td>{plan.name}</td>
                  <td style={{ width: "25%" }} className={`has-text-weight-bold ${(plan.data.length > 0) ? "has-text-danger" : "has-text-success"}`}>
                    {props.loading ? (
                      <Loader />
                    ) : (
                      `${plan.data.length}`
                    )}
                  </td>
                  <td>
                    <div class="buttons has-addons is-right">
                      <button onClick={() => { setEmails(plan.data); setOpenModal(true) }} className="button is-small is-info is-light" disabled={plan.data.length === 0}>
                        Click and see emails
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}

          </tbody>
        </table>
      </div>
      <div className={`modal ${openModal ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={closeModal}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Cancelled Emails - Cancel Date</p>
            <button
              className="delete"
              aria-label="close"
              onClick={closeModal}
            ></button>
          </header>
          <section className="modal-card-body">
            <ul>
              {emails.map((item, index) => {
                return (
                  <li key={index}>{item.email} - {new Date(item.cancel_date).toLocaleDateString()} {item.wasOnTrial && "Cancelled on trial"}</li>
                );
              })}
            </ul>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-info"
              onClick={closeModal}
            >
              Close
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};