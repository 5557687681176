import { Helmet } from "react-helmet";

function Courses(props) {
  return (
    <>
      <Helmet>
        <title>Nepeto - How to use Nepeto</title>
        <meta
          name="description"
          content="How to use Nepeto videos for Amazon Sellers for Free! From Beginners to Experts. How to source products, analyse products use a prep center and more! Learn here about using Nepeto for free."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, How to use Nepeto, How to use, course, courses, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <h1
        style={{
          fontSize: props.isMobile ? "5vh" : "4vw",
          marginTop: props.isMobile ? "2vh" : "5vh",
          color: "#8c3e7c",
          textAlign: "center",
        }}
      >
        How to use Nepeto
      </h1>

      <h1
        style={{
          fontSize: props.isMobile ? "2.5vh" : "2vw",
          marginTop: props.isMobile ? "2.5vh" : "6.5vh",
          color: "#8c3e7c",
          textAlign: "center",
        }}
        onClick={() => window.open("https://www.facebook.com/groups/1361718297857608/permalink/1430551784307592")}
      >
        Watch Webinar Recordings
      </h1>

      <h2         style={{
          fontSize: props.isMobile ? "2vh" : "1.5vw",
          marginTop: props.isMobile ? "2.5vh" : "6.5vh",
          color: "#8c3e7c",
          textAlign: "center",
          cursor: "pointer"
        }} onClick={() => window.open("https://drive.google.com/file/d/1Z215yUvc0jQR7XFKvl3JoiVolTzUlmO0/view?usp=sharing")} >► Click here to watch "Basics Tutorial" ◄</h2>
      <h2         style={{
          fontSize: props.isMobile ? "2vh" : "1.5vw",
          marginTop: props.isMobile ? "2.5vh" : "6.5vh",
          color: "#8c3e7c",
          textAlign: "center",
          cursor: "pointer"
        }} onClick={() => window.open("https://drive.google.com/file/d/1fE3CHRWaExeaQmoFoKXyF8DMxcuzdpPx/view?usp=sharing")} >► Click here to watch "Advanced Tutorial" ◄</h2>
      <h2         style={{
          fontSize: props.isMobile ? "2vh" : "1.5vw",
          marginTop: props.isMobile ? "2.5vh" : "6.5vh",
          color: "#8c3e7c",
          textAlign: "center",
          cursor: "pointer"
        }} onClick={() => window.open("https://www.facebook.com/groups/1361718297857608/permalink/1430551784307592")} >► Click here to watch webinar recordings ◄</h2>

      <br /><br />
      
      <h1
        style={{
          fontSize: props.isMobile ? "2.5vh" : "2vw",
          marginTop: props.isMobile ? "2.5vh" : "6.5vh",
          color: "#8c3e7c",
          textAlign: "center",
        }}
        onClick={() => window.open("https://www.facebook.com/groups/1361718297857608/permalink/1430551784307592")}
      >
        Schedule a live tutorial with our experts
      </h1>

      <h2         style={{
          fontSize: props.isMobile ? "2vh" : "1.5vw",
          marginTop: props.isMobile ? "2.5vh" : "6.5vh",
          color: "#8c3e7c",
          textAlign: "center",
          cursor: "pointer"
        }} onClick={() => window.open("https://calendly.com/igprojects/nepeto-tutorial-call")} >► Click here to schedule "Basics Tutorial" ◄</h2>
      <h2         style={{
          fontSize: props.isMobile ? "2vh" : "1.5vw",
          marginTop: props.isMobile ? "2.5vh" : "6.5vh",
          color: "#8c3e7c",
          textAlign: "center",
          cursor: "pointer"
        }} onClick={() => window.open("https://calendly.com/igprojects/nepeto-advanced-tutorial-call")} >► Click here to schedule "Advanced Tutorial" ◄</h2>
      <h2         style={{
          fontSize: props.isMobile ? "2vh" : "1.5vw",
          marginTop: props.isMobile ? "2.5vh" : "6.5vh",
          color: "#8c3e7c",
          textAlign: "center",
          cursor: "pointer",
          backgroundColor: "rgba(0,250,0,0.2)"
        }} onClick={() => window.open("https://calendly.com/nepetooneonone/30min")} >► Click here to schedule FREE ONE-ON-ONE!!! ◄</h2>
      
      <br /><br /><br /><br />
      <h1
        style={{
          fontSize: props.isMobile ? "5vh" : "4vw",
          marginTop: props.isMobile ? "2vh" : "5vh",
          color: "#8c3e7c",
          textAlign: "center",
        }}
      >
        Archived Videos
      </h1>
      <h1
        style={{
          fontSize: props.isMobile ? "2.5vh" : "2vw",
          marginTop: props.isMobile ? "2.5vh" : "6.5vh",
          color: "#8c3e7c",
          textAlign: "center",
        }}
      >
        What is Nepeto in 4 minutes
      </h1>
      <div
        style={{
          alignItems: "center",
          alignContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <iframe
          src="https://www.youtube-nocookie.com/embed/VUxhMIx5ggg?si=_PCWLPth-EJs6lzL"
          title="YouTube video player"
          style={{
            margin: "auto",
            border: "2px solid #8c3e7c",
            padding: "1%",
            width: props.isMobile ? "100vw" : "40vw",
            height: props.isMobile ? "30vh" : "45vh",
          }}
          frameborder="0"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <br />
      <br />
      <br />
      <h1
        style={{
          fontSize: props.isMobile ? "2.5vh" : "2vw",
          marginTop: props.isMobile ? "2.5vh" : "6.5vh",
          color: "#8c3e7c",
          textAlign: "center",
        }}
      >
        Sourcing From Retail Suppliers
      </h1>
      <div
        style={{
          alignItems: "center",
          alignContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <iframe
          src="https://www.youtube-nocookie.com/embed/rk3n_z4S-8I?si=VfFZXYx4nP8fySJ3"
          title="YouTube video player"
          style={{
            margin: "auto",
            border: "2px solid #8c3e7c",
            padding: "1%",
            width: props.isMobile ? "100vw" : "40vw",
            height: props.isMobile ? "30vh" : "45vh",
          }}
          frameborder="0"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <br />
        <br />
        <br />
        <h1
          style={{
            fontSize: props.isMobile ? "2.5vh" : "2vw",
            marginTop: props.isMobile ? "2.5vh" : "6.5vh",
            color: "#8c3e7c",
            textAlign: "center",
          }}
        >
          Sourcing OOS Products
        </h1>
        <iframe
          src="https://www.youtube-nocookie.com/embed/KIY2Ny-qwvs?si=DyvcTjCuhfP-8QQX"
          title="YouTube video player"
          style={{
            margin: "auto",
            border: "2px solid #8c3e7c",
            padding: "1%",
            width: props.isMobile ? "100vw" : "40vw",
            height: props.isMobile ? "30vh" : "45vh",
          }}
          frameborder="0"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <br />
        <br />
        <br />
        <h1
          style={{
            fontSize: props.isMobile ? "2.5vh" : "2vw",
            marginTop: props.isMobile ? "2.5vh" : "6.5vh",
            color: "#8c3e7c",
            textAlign: "center",
          }}
        >
          Sourcing Amazon Flips (A2A) Deals
        </h1>
        <iframe
          src="https://www.youtube-nocookie.com/embed/ODP92Vz8HUc?si=JLwZQqrpZ1Rzp3EB"
          title="YouTube video player"
          style={{
            margin: "auto",
            border: "2px solid #8c3e7c",
            padding: "1%",
            width: props.isMobile ? "100vw" : "40vw",
            height: props.isMobile ? "30vh" : "45vh",
          }}
          frameborder="0"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <br />
        <br />
        <br />
      </div>
    </>
  );
}

export default Courses;
