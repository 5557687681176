import './Dashboard.css'
import editIcon from './Components/editIcon.svg';
import wholesaleIcon from './Components/wholesaleIcon.svg';
import walmartIcon from './Components/walmartIcon.svg';
import soldoutIcon from './Components/soldoutIcon.svg';
import onlineArbIcon from './Components/onlineArbIcon.svg';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ModalForHiddenProducts from '../Products/Components/ModalForHiddenProducts';
import { useAuth0 } from '@auth0/auth0-react';
import ModalForBuyHCredits from './Components/ModalForBuyHCredits';
import ModalForFavorites from './Components/ModalForFavorites';

function Dashboard(props) {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [showHiddenProducts, setShowHiddenProducts] = useState(false);
  const [buyHideCreditsModal, setBuyHideCreditsModal] = useState(false);
  const [favorites, setFavorites] = useState(null);
  const [appExtAd, setAppExtAd] = useState(localStorage.getItem("appExtAd") !== "1");
  const [showFavoriteProducts, setShowFavoriteProducts] = useState(false);

  const getFavorites = async (e) => {
    e.target.style.cursor = "wait";
    e.target.disabled = true;
    const accessToken = await getAccessTokenSilently();
    fetch("https://server.nepeto.com/get_favorites/", {
      mode: "cors",
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then((response) => response.json()).then((data) => setFavorites(data.filter(product => !localStorage.getItem(product["url"].slice(-9) + product["asin"].split("/").pop() + "FAVHIDE"))));
  };

  return (
    <div style={{ minHeight: "80vh" }}>
      <div
        className="columns is-8"
        style={{ width: "90vw", margin: "auto", marginTop: "2vh" }}
      >
        <div className="column is-4">
          <div className="dashboard-block box" id="profileBlock">
            <i className="block-icon fa fa-user-o" aria-hidden="true"></i>
            <div className="heading bigheading" style={{ marginLeft: "3.5%" }}>{props.user.name}</div>
            <div className="title bigtitle" onClick={() => navigate("/pricing")} style={{ marginLeft: "3.15%" }}>
              {props.activePlanLevel === "0" ?
                <button className="gotobuttonmore button" onClick={() => window.location.href = "/pricing"}>Start a Free Trial</button>
                : props.activePlanLevel === "-1" ?
                  <button className="gotobuttonmore button" onClick={() => window.location.href = "/pricing"}>Subscribe to Nepeto</button> :
                  <>{props.activePlanLevel} <img onClick={() => navigate("/pricing")} src={editIcon} alt="editIcon" style={{ width: "16px", cursor: "pointer" }} /></>}</div>
            <div className="data-container" style={{ textAlign: "left", display: "flex" }}>
              <div className="data-block">
                <div className="heading">Hide Credits</div>
                <div className="title datanum">{props.userStatsLoading ? <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{ fontSize: "2.3vh" }}></i> : props.hideCredits} <i id="infoIcon" className="iconbutton fa fa-info-circle tooltip" aria-hidden="true">
                  <span className="tooltiptext" style={{ padding: "8px", fontSize: "90%", fontFamily: "sans-serif", position: "absolute", left: props.isMobile ? "0%" : "10%", marginTop: "-3%" }}>
                    Use Hide Credits to hide products<br />from other Nepeto users!<br />Each credit is worth 1 week of hiding.
                  </span>
                </i><br /><button className="gotobuttonsmall button" onClick={() => {(props.activePlanLevel !== "0" && props.activePlanLevel !== "-1") ? setBuyHideCreditsModal(true) : alert("Available only for subscribers.")}}>Buy</button></div>
              </div>
              <div className="data-block">
                <div className="heading">Hidden Products</div>
                <div className="title datanum">{props.userStatsLoading ? <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{ fontSize: "2.3vh" }}></i> : props.hidden.filter((obj1, i, arr) => arr.findIndex(obj2 => (obj2.asin === obj1.asin)) === i).length} <i className="iconbutton fa fa-info-circle tooltip" aria-hidden="true">
                  <span className="tooltiptext" style={{ padding: "8px", fontSize: "90%", fontFamily: "sans-serif", position: "absolute", left: props.isMobile ? "13%" : "37%", marginTop: "-3%" }}>
                    The products you hid<br />from other Nepeto users!
                  </span>
                </i><br /><button className="gotobuttonsmall button" onClick={() => setShowHiddenProducts(true)}>Show</button></div>
              </div>
              <div className="data-block">
                <div className="heading">Favorites</div>
                <div className="title datanum">{favorites === null ? <button className="gotobuttonsmall button" onClick={(e) => {getFavorites(e)}}>Load</button> : favorites.length} {favorites !== null && <><i className="iconbutton fa fa-info-circle tooltip" aria-hidden="true">
                  <span className="tooltiptext" style={{ padding: "8px", fontSize: "90%", fontFamily: "sans-serif", position: "absolute", left: props.isMobile ? "35%" : "64%", marginTop: "-3%" }}>
                    The products you added<br />to your favorites!<br />Add by clicking the heart icon.
                  </span>
                </i><br /><button className="gotobuttonsmall button" onClick={() => {setShowFavoriteProducts(true)}}>Show</button></>}</div>
              </div>
              <div className="data-block">
                <div className="heading">Mismatches</div>
                <div className="title datanum">{props.userStatsLoading ? <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{ fontSize: "2.3vh" }}></i> : props.matches}<span style={{ fontSize: "70%" }}>/50</span>{" "}
                  <i className="iconbutton fa fa-info-circle tooltip" aria-hidden="true">
                    <span className="tooltiptext" style={{ padding: "8px", fontSize: "90%", fontFamily: "sans-serif", position: "absolute", left: props.isMobile ? "70%" : "94%", marginTop: "-3%" }}>
                      Users who report 50 true mismatches/out of stock will receive a free subscription!
                    </span>
                  </i>
                  {props.matches >= 50 && <><br /><button onClick={() => navigate("/contact")} className="gotobuttonsmall button">Redeem</button></>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-4">
          <div className="dashboard-block box">
            <i className="block-icon fa fa-shopping-basket" aria-hidden="true" style={{ paddingRight: props.isMobile && "13.5%" }}></i>
            <div className="heading bigheading" style={{ marginLeft: "3.5%" }}>Online Arbitrage</div>
            <div className="title bigtitle" onClick={() => navigate("/retail")} style={{ marginLeft: "3.15%" }}>Online Arbitrage Retailers</div>
            <div className="smalltitle" style={{ marginLeft: "3.15%", marginTop: "-2.5vh" }}>Buy online and resell on Amazon.<br/>
            <span onClick={() => navigate("/retail")} style={{borderRadius: "50px", backgroundColor: "rgba(72,199,142, 0.9)", padding: "0.5% 5% 1%", color: "rgba(255,255,255,0.95)", fontWeight: "700", fontSize: "70%"}}>Most Recommended</span></div>
            <button className="gotobutton button" id="retailSourcing" onClick={() => navigate("/retail")} style={{ marginLeft: "3.15%" }}>Click to Source OA Retailers <i class="fa fa-star" aria-hidden="true" style={{color: "rgba(72,199,142)", marginTop: "2px", marginLeft: "5px"}}></i></button>
          </div>
        </div>
        <div className="column is-4">
          <div className="dashboard-block box">
            <img src={onlineArbIcon} alt="wwwLogo" className="block-icon block-icon-img" />
            <div className="heading bigheading" style={{ marginLeft: "3.5%" }}>Online Arbitrage</div>
            <div className="title bigtitle" onClick={() => navigate("/live-search")} style={{ marginLeft: "3.15%" }}>Live Search !! <i class="fa fa-star" aria-hidden="true" style={{color: "rgba(72,199,142)", marginTop: "2px", marginLeft: "5px"}}></i></div>
            <div className="smalltitle" style={{ marginLeft: "3.15%", marginTop: "-2.5vh" }}>Use our powerful algorithm to source live! Nepeto's power is in your hands.</div>
            <button className="gotobutton button" id="liveSourcing" onClick={() => navigate("/live-search")} style={{ marginLeft: "3.15%" }}>Click to Source Live!</button>
          </div>
        </div>
      </div>

      <div
        className="columns is-8"
        style={{ width: "90vw", margin: "auto", marginTop: "2vh" }}
      >
        <div className="column is-4">
          <div className="dashboard-block box">
          <i className="block-icon fa fa-search" aria-hidden="true"></i>
            <div className="heading bigheading" style={{ marginLeft: "3.5%" }}>OA ON STEROIDS</div>
            <div className="title bigtitle" onClick={() => navigate("/storefront-scanner")} style={{ marginLeft: "3.15%" }}>Storefront Scanner</div>
            <div className="smalltitle" style={{ marginLeft: "3.15%", marginTop: "-2.5vh" }}>Scan a storefront and see where they buy their leads.</div>
            <button className="gotobutton button" id="storeFrontScanner" onClick={() => navigate("/storefront-scanner")} style={{ marginLeft: "3.15%" }}>Click to Scan a Storefront</button>
          </div>
        </div>
        <div className="column is-4">
          <div className="dashboard-block box">
            <img src={soldoutIcon} alt="wholesaleIcon" className="block-icon block-icon-img" />
            <div className="heading bigheading" style={{ marginLeft: "3.5%" }}>AMZ OOS Opportunities</div>
            <div className="title bigtitle" onClick={() => navigate("/oos")} style={{ marginLeft: "3.15%" }}>Be The Only Seller</div>
            <div className="smalltitle" style={{ marginLeft: "3.15%", marginTop: "-2.5vh" }}>Sell products that are out of stock on Amazon and be the only seller.</div>
            <button className="gotobutton button" id="oosSourcing" onClick={() => navigate("/oos")} style={{ marginLeft: "3.15%" }}>Click to Source OOS</button>
          </div>
        </div>
        <div className="column is-4">
          <div className="dashboard-block box">
          <i className="block-icon fa fa-list" aria-hidden="true"></i>
            <div className="heading bigheading" style={{ marginLeft: "3.5%" }}>FASTER OA SOURCING</div>
            <div className="title bigtitle" onClick={() => navigate("/master-search")} style={{ marginLeft: "3.15%" }}>Nepeto Master Search <i class="fa fa-star" aria-hidden="true" style={{color: "rgba(72,199,142)", marginTop: "2px", marginLeft: "5px"}}></i></div>
            <div className="smalltitle" style={{ marginLeft: "3.15%", marginTop: "-2.5vh" }}>Use the Nepeto Master Search to find the best products FASTER.</div>
            <button className="gotobutton button" onClick={() => navigate("/master-search")} style={{ marginLeft: "3.15%" }}>Click to Find Products</button>
          </div>
        </div>
      </div>

      <div
        className="columns is-8"
        style={{ width: "90vw", margin: "auto", marginTop: "2vh" }}
      >
<div className="column is-4">
          <div className="dashboard-block box">
            <img src={wholesaleIcon} alt="wholesaleIcon" className="block-icon block-icon-img" />
            <div className="heading bigheading" style={{ marginLeft: "3.5%" }}>Wholesale - Distributors - Online</div>
            <div className="title bigtitle" onClick={() => navigate("/distributors")} style={{ marginLeft: "3.15%" }}>Wholesale/Distributors</div>
            <div className="smalltitle" style={{ marginLeft: "3.15%", marginTop: "-2.5vh" }}>Buy online in <span style={{ fontWeight: "700" }}>bulk</span> and resell on Amazon.</div>
            <button className="gotobutton button" id="wholesaleSourcing" onClick={() => navigate("/distributors")} style={{ marginLeft: "3.15%" }}>Click to Source Wholesale</button>
          </div>
        </div>
        <div className="column is-4">
          <div className="dashboard-block box">
            <i className="block-icon fa fa-refresh" aria-hidden="true"></i>
            <div className="heading bigheading" style={{ marginLeft: "3.5%" }}>A2A Flips</div>
            <div className="title bigtitle" onClick={() => navigate("/amazon-flips")} style={{ marginLeft: "3.15%" }}>Amazon to Amazon Flips</div>
            <div className="smalltitle" style={{ marginLeft: "3.15%", marginTop: "-2.5vh" }}>Buy from Amazon on a price drop and sell on Amazon when the price goes back up.</div>
            <button className="gotobutton button" id="a2aSourcing" onClick={() => navigate("/amazon-flips")} style={{ marginLeft: "3.15%" }}>Click to Source A2A</button>
          </div>
        </div>
        <div className="column is-4">
          <div className="dashboard-block box">
            <i className="block-icon fa fa-info" aria-hidden="true"></i>
            <div className="heading bigheading" style={{ marginLeft: "3.5%" }}>More</div>
            <button className="gotobuttonmore button" id="chromeExtBtn" onClick={() => navigate("/chrome-extension")} style={{ marginLeft: "3.15%", fontSize: "1.7vh" }}>Chrome Extensions</button>
            <button className="gotobuttonmore button" id="appExt" onClick={() => navigate("/app-ext")} style={{ marginLeft: "3.15%", fontSize: "1.7vh" }}>Mobile App</button>
            <button className="gotobuttonmore button" onClick={() => window.open("https://www.facebook.com/share/ofb5faZtiNzMDmPs/?mibextid=K35XfP")} style={{ marginLeft: "3.15%", fontSize: "1.7vh" }}><i class="fa fa-facebook-official" aria-hidden="true" style={{color: "rgb(8, 102, 255)"}}></i>&nbsp;Community!</button>
            <button className="gotobuttonmore button" onClick={() => window.open("https://discord.gg/jg2vmgMrz9")} style={{ marginLeft: "3.15%", fontSize: "1.7vh" }}>Discord Server</button>
            {/* <button className="gotobuttonmore button" style={{ marginLeft: "3.15%" }}>Webinars</button> */}
          </div>
        </div>
      </div>

      <i class="fa fa-arrow-down upDown" aria-hidden="true" style={{position: 'absolute', fontSize: "4vw", top: "90vh", marginLeft: ".9vw", color: "#8B3C7E"}}></i>
      <div
        className="columns is-8"
        style={{ width: "90vw", margin: "auto", marginTop: "2vh" }}
      >
        <div className="column is-4">
          <div className="dashboard-block box">
          <i className="block-icon fa fa-table" aria-hidden="true"></i>
            <div className="heading bigheading" style={{ marginLeft: "3.5%" }}>Wholesale ON STEROIDS</div>
            <div className="title bigtitle" onClick={() => navigate("/csv-scanner")} style={{ marginLeft: "3.15%" }}>CSV Scanner <i class="fa fa-star" aria-hidden="true" style={{color: "rgba(72,199,142)", marginTop: "2px", marginLeft: "5px"}}></i></div>
            <div className="smalltitle" style={{ marginLeft: "3.15%", marginTop: "-2.5vh" }}>Scan a CSV file and get all the data you need about the products.</div>
            <button className="gotobutton button" onClick={() => navigate("/csv-scanner")} style={{ marginLeft: "3.15%" }}>Click to Scan a CSV</button>
          </div>
        </div>
        <div className="column is-4">
          <div className="dashboard-block box">
            <img src={walmartIcon} alt="walmartIcon" className="block-icon block-icon-img" />
            <div className="heading bigheading" style={{ marginLeft: "3.5%" }}>For Walmart Sellers</div>
            <div className="title bigtitle" onClick={() => navigate("/amazon-to-walmart")} style={{ marginLeft: "3.15%" }}>Amazon to Walmart Products</div>
            <div className="smalltitle" style={{ marginLeft: "3.15%", marginTop: "-2.5vh" }}>Buy from Amazon and sell on Walmart.</div>
            <button className="gotobutton button" id="a2wSourcing" onClick={() => navigate("/amazon-to-walmart")} style={{ marginLeft: "3.15%" }}>Click to Source A2W</button>
          </div>
        </div>
        <div className="column is-4">
          <div className="dashboard-block box">
          <i className="block-icon fa fa-play" aria-hidden="true"></i>
            <div className="heading bigheading" style={{ marginLeft: "3.5%" }}>HOW TO USE NEPETO</div>
            <div className="title bigtitle" onClick={() => window.open("https://www.facebook.com/groups/1361718297857608/permalink/1430551784307592")} style={{ marginLeft: "3.15%" }}>Watch Webinar Recordings</div>
            <div className="smalltitle" onClick={() => window.open("https://drive.google.com/file/d/1Z215yUvc0jQR7XFKvl3JoiVolTzUlmO0/view?usp=sharing")} style={{ marginLeft: "3.15%", cursor: "pointer", marginTop: "-2.5vh", textDecoration: "underline", marginBottom: "1%" }}>1) Click to watch "Basics Tutorial"</div>
            <div className="smalltitle" onClick={() => window.open("https://drive.google.com/file/d/1fE3CHRWaExeaQmoFoKXyF8DMxcuzdpPx/view?usp=sharing")} style={{ marginLeft: "3.15%", cursor: "pointer", textDecoration: "underline", marginBottom: "1%" }}>2) Click to watch "Advanced Tutorial"</div>
            <div className="smalltitle" onClick={() => window.open("https://www.facebook.com/groups/1361718297857608/permalink/1430551784307592")} style={{ marginLeft: "3.15%", cursor: "pointer", textDecoration: "underline" }}>3) Click to watch webinar recordings</div>
          </div>
        </div>
      </div>
      <br /><br />

      {showHiddenProducts && <ModalForHiddenProducts
        isMobile={props.isMobile}
        setShowHiddenProducts={setShowHiddenProducts}
        showHiddenProducts={showHiddenProducts}
        hidden={props.hidden}
        hideCredits = {props.hideCredits}
      />}
      {buyHideCreditsModal && (
        <ModalForBuyHCredits setBuyHideCreditsModal={setBuyHideCreditsModal}/>
      )}
      {showFavoriteProducts && <ModalForFavorites products={favorites} setProducts={setFavorites} setShowFavoriteProducts={setShowFavoriteProducts} showFavoriteProducts={showFavoriteProducts}/>}

      {appExtAd && (
        <div id="modal-js-example" className="modal is-active">
        <div class="modal-background" onClick={() => { setAppExtAd(false); localStorage.setItem("appExtAd", "1"); }}></div>
  
        <div className="modal-content" style={{width: props.isMobile ? "90vw" : "40vw"}}>
          <div className="box" style={{ textAlign: "center" }}>
            <p style={{ fontSize: "200%" }}>Don't miss out!</p><br />
            <p style={{ fontSize: "150%" }}>Our new Scanning Mobile App and Chrome Extension Profit Calculator are GAME CHANGERS!</p><br />
            <img src={props.isMobile? "/assets/appextlandmobile.png" : "/assets/appextland2.jpg"} style={{ width: "80vw", margin: "auto" }} /><br /><br />
            <button class="button is-success" style={{fontSize: props.isMobile ? "170%" : "1.8vw"}} onClick={() => { setAppExtAd(false); localStorage.setItem("appExtAd", "1"); window.location.href = "/app-ext" }}>Click Here{props.isMobile ? "" : " to Save Time & Money!"}</button>
            <br /><br /><button class="button is-danger" onClick={() => { setAppExtAd(false); localStorage.setItem("appExtAd", "1"); }}>Close</button>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}

export default Dashboard;
